import { TypeDescription } from '../../typing/type-description.js';
import { HttpTreeNode } from './http-tree-node.js';

export function paramNode<Node extends HttpTreeNode<any, any, any>>(
  node: Node,
  name: string,
  type: TypeDescription,
): Node {
  return {
    ...node,
    parent: node,
    segments: [
      ...node.segments,
      {
        param: name.toString(),
        type: 'param',
        typeDescription: type,
      },
    ],
  };
}

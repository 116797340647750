import { getPathSegment } from './get-path-segment.js';
import { HttpRequest } from '../http-request.js';
import { HttpTreeNode } from './http-tree-node.js';

export function pathNode<Request extends HttpRequest, Response, Params>(
  node: HttpTreeNode<Request, Response, Params>,
  path: string,
): HttpTreeNode<Request, Response, Params> {
  const segments = getPathSegment(path);

  return {
    ...node,
    segments: [...node.segments, ...segments],
  };
}

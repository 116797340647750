import {
  StateDefinition,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { SnapshotIndexClient } from '@aion/core/storage/snapshot-index-client.js';
import { createRemoteSnapshotIndexClient } from './create-remote-snapshot-index-client.js';
import { iterateResponse } from './iterate-response.js';
import { RemoteDescription } from './remote-description.js';
import { RpcClient } from '../api/create-rpc-client.js';
import { SessionManager } from '../auth/jwt-session.js';
import { Lazy } from '@aion/core/lazy/lazy.js';
import { mapToState } from '@aion/core/runtime/get-state-data-keys.js';
import { RangeResult } from '@aion/core/storage/range-result.js';
import { RangeOptions } from '@aion/core/storage/range-options.js';
import { GetOptions } from '@aion/core/storage/get-options.js';

export function createRemoteSnapshotClient<
  TState extends StateDefinition<any, any, any>,
>(
  state: TState,
  rpc: RpcClient,
  session: SessionManager,
  lazyVersion: Lazy<string>,
  type: 'stream' | 'aggregation',
  streamIdentifier: RemoteDescription,
  stateName: string,
): SnapshotClient<TState> {
  return {
    async get(
      id: StateKeyValues<TState>,
      opts: GetOptions,
    ): Promise<RangeResult<TypeOfStateDeclaration<TState>> | null> {
      const response = await rpc['state.get'].call(
        {
          id,
          state: stateName,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          tenant: streamIdentifier.tenant,
          realm: streamIdentifier.realm,
          args: streamIdentifier.args,
          token: await session.resolveToken(),
          type,
        },
        opts.signal,
      );
      return {
        data: mapToState(state, response.data),
        keys: response.keys,
      };
    },
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): SnapshotIndexClient<TState, TState['indices'][K]> {
      return createRemoteSnapshotIndexClient(
        state,
        state.indices[index],
        0,
        rpc,
        session,
        lazyVersion,
        type,
        streamIdentifier,
        stateName,
        index,
        {},
      );
    },
    async *range(
      bookmark: StateKeyValues<TState> | null,
      options: RangeOptions,
    ): AsyncGenerator<RangeResult<any>> {
      yield* iterateResponse(
        state,
        bookmark ?? null,
        options,
        async (bookmark, rangeOptions) =>
          rpc['state.browse'].call(
            {
              state: stateName,
              version: await lazyVersion.resolve(),
              pattern: streamIdentifier.pattern,
              tenant: streamIdentifier.tenant,
              realm: streamIdentifier.realm,
              bookmark: bookmark ?? undefined,
              open: rangeOptions.open,
              direction: rangeOptions.dir,
              args: streamIdentifier.args,
              token: await session.resolveToken(),
              type,
              take: undefined,
            },
            options.signal,
          ),
      );
    },
  };
}

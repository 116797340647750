import { AuthProviders } from '@aion/core/authentication/auth-providers.js';
import { CustomError } from '@aion/core/error/custom-error.js';
import { JwtSessionData } from '../auth/jwt-session-data.js';
import { AuthParams } from '../auth/auth-params.js';
import { buildJwtSessionData } from '../auth/build-jwt-session-data.js';
import { createJwtSessionHandler } from '../auth/create-jwt-session-handler.js';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';
import { createRpcClient } from '../api/create-rpc-client.js';
import { getOrCreate } from '@aion/core/utils/get-or-create.js';
import { createSessionManager } from '../auth/jwt-session.js';
import { JwtPayload } from '@aion/core/authentication/jwt/jwt-payload.js';
import { createDelayedEmitter } from '@aion/core/emitter/create-emitter.js';
import { JwtSessionStorage } from '../auth/jwt-session-storage.js';
import { parseJwt } from '@aion/core/authentication/jwt/parse-jwt.js';
import { OAuthTransferStateData } from './o-auth-transfer-state-data.js';
import {
  OAuthEventData,
  OAuthRemoteInterface,
} from './o-auth-remote-interface.js';
import { UnauthenticatedSession } from './unauthenticated-session.js';
import { AuthenticatedSession } from './authenticated-session.js';
import { OAuthOptions } from './o-auth-options.js';

import { AuthDeviceResponse } from '@aion/core/authentication/oauth/auth-device-response.js';
import { createLogger } from '@aion/core/logger/logger.js';
import { TokenAuthDefinitions } from '@aion/core/authentication/token-auth-definitions.js';
import { OpenIdAuthDefinitions } from '@aion/core/authentication/open-id-auth-definitions.js';
import { JwtAuthDefinitions } from '@aion/core/authentication/jwt-auth-definitions.js';
import { BadRequestError } from '@aion/core/error/bad-request-error.js';

async function loadTransferSession(
  realm: RealmDefinition<any>,
  params: AuthParams,
  options: OAuthStorage,
): Promise<JwtSessionData | null> {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(
      window.location.search.substring(window.location.search.indexOf('?')),
    );
    const state = searchParams.get('state');
    const code = searchParams.get('code');

    if (!!state && !!code) {
      const transferState = await options.transferStorage.load();
      if (!transferState) {
        return await options.sessionStorage.load();
      }

      await options.transferStorage.clear();

      const api = createRpcClient({ url: params.url, env: params.env });
      const response = await api['auth.authorize'].call(
        {
          realm: transferState.realm,
          tenant: transferState.tenant,
          code,
          redirectUri: transferState.redirectUri,
          provider: transferState.provider,
        },
        params.signal,
      );
      const sessionData = buildJwtSessionData(
        realm,
        params.tenant,
        transferState.provider,
        response,
      );
      await options.sessionStorage.save(sessionData);
      return sessionData;
    }
  }

  return await options.sessionStorage.load();
}

interface OAuthStorage {
  sessionStorage: JwtSessionStorage<JwtSessionData>;
  transferStorage: JwtSessionStorage<OAuthTransferStateData>;
}

function createOAuthInterface<TAuth extends AuthProviders>(
  realm: RealmDefinition<TAuth>,
  params: AuthParams,
  opts: OAuthStorage,
): OAuthRemoteInterface<TAuth> {
  const emitter = createDelayedEmitter<
    UnauthenticatedSession | AuthenticatedSession
  >(
    (async () => {
      const sessionData = await loadTransferSession(realm, params, {
        sessionStorage: opts.sessionStorage,
        transferStorage: opts.transferStorage,
      });
      if (sessionData) {
        handleSession(sessionData);
      }
      return sessionToAuthState(sessionData);
    })(),
  );

  const logger = createLogger('oauth');

  const sessionManager = createSessionManager();
  const api = createRpcClient({ url: params.url, env: params.env });

  function handleSession(sessionData: JwtSessionData) {
    sessionManager.addSession((signal) =>
      createJwtSessionHandler(api, {
        data: sessionData,
        signal,
        sessionStorage: opts.sessionStorage,
      }),
    );
  }

  async function updateSession(sessionData: JwtSessionData) {
    await opts.sessionStorage.save(sessionData);

    sessionManager.clearSession();
    handleSession(sessionData);

    const currentSession = sessionToAuthState(sessionData);
    emitter.emit(currentSession);
  }

  function sessionToAuthState(
    session: JwtSessionData | null,
  ): AuthenticatedSession | UnauthenticatedSession {
    if (session) {
      const payload = parseJwt(session.accessToken, 1, JwtPayload);
      return {
        type: 'authenticated',
        auth: {
          iss: payload.iss,
          aud: payload.aud,
          sub: payload.sub,
        },
        payload,
      };
    } else {
      return { type: 'unauthenticated' };
    }
  }

  async function logout() {
    await opts.sessionStorage.clear();
    await opts.transferStorage.clear();
    sessionManager.clearSession();
    emitter.emit({ type: 'unauthenticated' });
  }

  return {
    sessions: {
      async resolveToken(): Promise<string | undefined> {
        try {
          return await sessionManager.resolveToken();
        } catch (e) {
          if (e instanceof CustomError) {
            if (e.data['error'] === 'no session') {
              logout().catch((err) => {
                logger.error(err);
              });
            }
          } else if (e instanceof BadRequestError) {
            if (e.message === 'unknown session') {
              logout().catch((err) => {
                logger.error(err);
              });
            }
          }
          throw e;
        }
      },
    },
    on(cb: (evt: OAuthEventData) => void, signal: AbortSignal): void {
      emitter.on(cb, signal);
    },
    async login(
      provider: keyof JwtAuthDefinitions<TAuth> & string,
      username: string,
      password: string,
      signal: AbortSignal,
    ): Promise<void> {
      const config = realm.providers[provider];

      if (!config) {
        throw new CustomError('unknown provider', {
          provider,
        });
      }

      const api = createRpcClient({ url: params.url, env: params.env });
      const response = await api['auth.login'].call(
        {
          tenant: params.tenant,
          realm: realm.name,
          provider,
          username,
          password,
        },
        signal,
      );
      const sessionData = buildJwtSessionData(
        realm,
        params.tenant,
        provider,
        response,
      );
      await updateSession(sessionData);
    },
    async register(
      provider: keyof JwtAuthDefinitions<TAuth> & string,
      username: string,
      password: string,
      signal: AbortSignal,
    ): Promise<void> {
      const config = realm.providers[provider];

      if (!config) {
        throw new CustomError('unknown provider', {
          provider,
        });
      }

      const api = createRpcClient({ url: params.url, env: params.env });
      const response = await api['auth.register'].call(
        {
          tenant: params.tenant,
          realm: realm.name,
          provider,
          username,
          password,
        },
        signal,
      );
      const sessionData = buildJwtSessionData(
        realm,
        params.tenant,
        provider,
        response,
      );
      await updateSession(sessionData);
    },
    async *deviceLogin(
      provider: keyof OpenIdAuthDefinitions<TAuth> & string,
      signal: AbortSignal,
    ): AsyncGenerator<AuthDeviceResponse> {
      const config = realm.providers[provider];

      if (!config) {
        throw new CustomError('unknown provider', {
          provider,
        });
      }

      const api = createRpcClient({ url: params.url, env: params.env });
      for await (const response of api['auth.deviceCode'].call(
        {
          tenant: params.tenant,
          realm: realm.name,
          provider,
        },
        signal,
      )) {
        if (response.type === 'token') {
          const sessionData = buildJwtSessionData(
            realm,
            params.tenant,
            provider,
            response.token,
          );
          await updateSession(sessionData);
        }
        yield response;
      }
    },
    async tokenLogin(
      provider: keyof TokenAuthDefinitions<TAuth> & string,
      token: string,
      signal: AbortSignal,
    ): Promise<void> {
      const config = realm.providers[provider];

      if (!config) {
        throw new CustomError('unknown provider', {
          provider,
        });
      }

      const api = createRpcClient({ url: params.url, env: params.env });
      const response = await api['auth.token'].call(
        {
          tenant: params.tenant,
          realm: realm.name,
          provider,
          token,
        },
        signal,
      );
      const sessionData = buildJwtSessionData(
        realm,
        params.tenant,
        provider,
        response,
      );
      await updateSession(sessionData);
    },
    navigateToLogin: async (provider) => {
      const state = Math.random().toString(36);
      const config = realm.providers[provider];

      if (!config) {
        throw new CustomError('unknown provider', {
          provider,
        });
      }

      if (config.type !== 'openid') {
        throw new CustomError('expected openid provider', {
          provider,
        });
      }

      if (!config.authorize) {
        throw new CustomError('authorized not supported', {
          provider,
        });
      }

      const qs = {
        ...config.authorize.queryParams,
        state,
        redirect_uri: window.location.origin + window.location.pathname,
      };

      await opts.transferStorage.save({
        redirectUri: qs.redirect_uri,
        provider: provider,
        tenant: params.tenant,
        realm: realm.name,
      });

      window.location.href = `${config.authorize.url}?${Object.entries(qs)
        .map(([key, value]) => `${key}=${String(value)}`)
        .join('&')}`;
    },
    logout,
    async resolveAuth(): Promise<
      UnauthenticatedSession | AuthenticatedSession
    > {
      return await emitter.value();
    },
  };
}

export function createOAuthStorage<TAuth extends AuthProviders>(
  realm: RealmDefinition<TAuth>,
  opts: OAuthOptions,
): (params: AuthParams) => OAuthRemoteInterface<TAuth> {
  const interfaces: Record<string, OAuthRemoteInterface<any>> = {};

  return (params) => {
    const key = `${params.tenant}:${params.url}`;
    const sessionStorage = opts.sessionStorageFactory(
      `auth_session_${key}`,
      JwtSessionData,
    );

    const transferStorage = opts.transferStorageFactory(
      `auth_transfer_${key}`,
      OAuthTransferStateData,
    );
    return getOrCreate(interfaces, key, () =>
      createOAuthInterface(realm, params, { sessionStorage, transferStorage }),
    );
  };
}

import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { StreamArgs } from './stream-args.js';
import { optional } from '@aion/core/typing/optional.js';
import { date } from '@aion/core/typing/date.js';
import { record } from '@aion/core/typing/record.js';
import { StateValue } from '@aion/core/storage/state-value.js';
import { boolean } from '@aion/core/typing/boolean.js';
import { array } from '@aion/core/typing/array.js';
import { TypeOf } from '@aion/core/typing/type-of.js';

export const EventPush = object('PushEvent', {
  realm: string(),
  tenant: string(),
  version: string(),
  pattern: string(),
  args: StreamArgs,
  await: optional(boolean()),
  event: string(),

  id: optional(string()),
  createdAt: optional(date()),
  data: record(string(), StateValue),
  annotations: optional(record(string(), string())),

  token: optional(string()),
});

export const EventPushBatchItem = object('EventPushBatchItem', {
  event: string(),
  id: string(),
  createdAt: date(),
  data: record(string(), StateValue),
  annotations: record(string(), string()),
});
export type EventPushBatchItem = TypeOf<typeof EventPushBatchItem>;

export const EventPushBatch = object('EventPushBatch', {
  realm: string(),
  tenant: string(),
  version: string(),
  pattern: string(),
  args: StreamArgs,
  await: optional(boolean()),

  events: array(EventPushBatchItem),

  token: optional(string()),
});

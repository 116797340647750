export function reusableResolvable<T>() {
  let promise: Promise<T> | null = null;
  return {
    resolve(fn: () => Promise<T>): Promise<T> {
      if (!promise) {
        promise = fn().finally(() => {
          promise = null;
        });
      }
      return promise;
    },
  };
}

import { InjectionToken, Provider } from '@angular/core';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';
import { AuthProviders } from '@aion/core/authentication/auth-providers.js';

export interface ApiConfig {
  host: string;
  tenant: string;
  realm: RealmDefinition<AuthProviders>;
}

export interface AuthConfig {
  unauthorizedUrl: string;
  authorizedUrl: string;
  logoUrl: string;
}

export const ApiConfig = new InjectionToken<ApiConfig>(
  'iwentsourcing-api-config',
);

export const AuthConfig = new InjectionToken<AuthConfig>(
  'iwentsourcing-auth-config',
);

export function provideIwentsourcingApi(opts: ApiConfig): Provider {
  return {
    provide: ApiConfig,
    useValue: opts,
  };
}

export function provideIwentsourcingAuth(opts: AuthConfig): Provider {
  return {
    provide: AuthConfig,
    useValue: opts,
  };
}

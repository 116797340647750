import {
  StateDeclarations,
  StateDefinitions,
} from '@aion/core/storage/state-declaration.js';
import { EventDeclarations } from '@aion/core/event-store/event-declarations.js';
import { AggregateHostRuntime } from '@aion/core/runtime/host-runtime.js';
import { RemoteManagement } from './remote-management.js';
import {
  RemoteManagementOptions,
  SessionInterface,
} from './remote-management-options.js';
import { createRemoteStreamInterface } from './create-remote-stream-interface.js';
import { createRemoteAggregationInterface } from './create-remote-aggregation-interface.js';
import { StreamInstanceOptions } from '@aion/core/stream/stream-instance-options.js';
import { StreamInterface } from '@aion/core/stream/stream-interface.js';
import { createRpcClient } from '../api/create-rpc-client.js';
import { createLazy } from '@aion/core/lazy/create-lazy.js';
import { AggregateInterface } from '@aion/core/aggregation/aggregate-interface.js';
import { RemoteStream } from './remote-stream.js';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';
import { AuthProviders } from '@aion/core/authentication/auth-providers.js';

export function createRemoteManagement<Session extends SessionInterface>(
  opts: RemoteManagementOptions<Session>,
): RemoteManagement<Session> {
  const rpc = createRpcClient({ url: opts.url, env: opts.env });
  const session = opts.session({
    url: opts.url,
    tenant: opts.tenant,
    signal: opts.signal,
    env: opts.env,
  });

  return {
    session,
    getStream<
      TState extends StateDefinitions,
      TEvent extends EventDeclarations,
    >(
      stream: RemoteStream<TState, TEvent>,
      options?: Partial<Omit<StreamInstanceOptions, 'auth'>>,
    ): StreamInterface<TState, TEvent> {
      const lazyVersion = createLazy(() => stream.version.resolve(opts.env));
      return createRemoteStreamInterface(
        stream.state,
        stream.events,
        rpc,
        session.sessions,
        lazyVersion,
        {
          tenant: opts.tenant,
          realm: stream.realm,
          version: stream.version,
          pattern: stream.pattern,
          args: options?.args ?? {},
        },
      );
    },
    getAggregation<TState extends StateDeclarations>(
      aggregation: AggregateHostRuntime<TState, RealmDefinition<AuthProviders>>,
      options?: Partial<Omit<StreamInstanceOptions, 'auth'>>,
    ): AggregateInterface<TState> {
      const lazyVersion = createLazy(() =>
        aggregation.source.version.resolve(opts.env),
      );
      return createRemoteAggregationInterface(
        aggregation.source.state,
        rpc,
        session.sessions,
        lazyVersion,
        {
          tenant: opts.tenant,
          realm: aggregation.source.realm.name,
          pattern: aggregation.source.pattern,
          version: aggregation.source.version,
          args: options?.args ?? {},
        },
      );
    },
  };
}

import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { isStateValue, StateValue } from '../storage/state-value.js';
import { CustomError } from '../error/custom-error.js';
import { TypeDescription } from '../typing/type-description.js';
import { ObjectTypeDescription } from '../typing/object-type-description.js';
import { failNever } from '../utils/fail-never.js';
import { isArray } from '../typing/array.js';
import { getDate } from '../typing/date.js';
import { UpsertType } from '../storage/upsert-type.js';
import { PatchType } from '../storage/patch-type.js';

export function getStateDataKeys<TState extends StateDefinition<any, any, any>>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): {
  data: Record<string, StateValue>;
  keys: Record<string, StateValue>;
} {
  return {
    data: getStateData(state.type, val),
    keys: getStateKeys(state, val),
  };
}

export function getStateData<TState extends StateDefinition<any, any, any>>(
  description: ObjectTypeDescription<TypeOfStateDeclaration<TState>>,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  return iterateData(description, '', val);
}

export function iterateData(
  type: TypeDescription,
  prefix: string,
  val: unknown,
): Record<string, StateValue> {
  if (type.type === 'string') {
    if (typeof val === 'string') {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map string', {
        key: prefix,
      });
    }
  } else if (type.type === 'number') {
    if (typeof val === 'number') {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map number', {
        key: prefix,
      });
    }
  } else if (type.type === 'boolean') {
    if (typeof val === 'boolean') {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map boolean', {
        key: prefix,
      });
    }
  } else if (type.type === 'date') {
    const date = getDate(val);
    if (date) {
      return { [prefix]: date };
    } else {
      throw new CustomError('unable to map date', {
        key: prefix,
      });
    }
  } else if (type.type === 'numberAsText') {
    if (typeof val === 'string' && !isNaN(parseFloat(val))) {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map numberAsText', {
        key: prefix,
      });
    }
  } else if (type.type === 'literal') {
    if (
      val === type.constant &&
      (typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean')
    ) {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map literal', {
        key: prefix,
      });
    }
  } else if (type.type === 'array') {
    if (isArray(val)) {
      const items = Object.values(val);
      let data: Record<string, StateValue> = {};
      for (let i = 0; i < items.length; i++) {
        const itemValue = iterateData(
          type.itemType,
          `${prefix}.${i}`,
          items[i],
        );
        if (itemValue) {
          data = { ...data, ...itemValue };
        } else {
          throw new CustomError('unable to map array item', {
            key: prefix,
            index: i.toString(),
          });
        }
      }
      return data;
    } else {
      throw new CustomError('unable to map array', {
        key: prefix,
      });
    }
  } else if (type.type === 'object') {
    if (val === null || val === undefined || typeof val !== 'object') {
      throw new CustomError('unable to map empty object', {
        key: prefix,
      });
    }

    let data: Record<string, StateValue> = {};
    const props: string[] = [];
    for (const [key, value] of Object.entries(val)) {
      const propType = type.object[key];
      props.push(key);
      if (propType) {
        const propData = iterateData(
          propType,
          prefix.length > 0 ? `${prefix}.${key}` : key,
          value,
        );
        if (!propData) {
          throw new CustomError('unable to map object property', {
            key: prefix,
            propertyName: key,
          });
        }
        data = { ...data, ...propData };
      }
    }

    const typeProps = Object.entries(type.object);
    const missedTypeProps = typeProps.filter(
      ([name]) => props.indexOf(name) === -1,
    );
    for (const [name, missedProp] of missedTypeProps) {
      const propData = iterateData(
        missedProp,
        prefix.length > 0 ? `${prefix}.${name}` : name,
        name in val ? (val as any)[name] : undefined,
      );
      if (!propData) {
        throw new CustomError('unable to map object property', {
          key: prefix,
          propertyName: name,
        });
      }
      data = { ...data, ...propData };
    }

    return data;
  } else if (type.type === 'optional') {
    try {
      const res = iterateData(type.optionalType, prefix, val);
      return res;
    } catch (e) {
      if (e instanceof CustomError) {
        return {};
      } else {
        throw e;
      }
    }
  } else if (type.type === 'nullable') {
    try {
      return iterateData(type.nullableType, prefix, val);
    } catch (e) {
      if (e instanceof CustomError) {
        return {};
      } else {
        throw e;
      }
    }
  } else if (type.type === 'union') {
    for (const unionType of type.unionTypes) {
      try {
        const data = iterateData(unionType, prefix, val);
        return data;
      } catch (e) {
        if (!(e instanceof CustomError)) {
          throw e;
        }
      }
    }
    throw new CustomError('unable to map union', {
      key: prefix,
    });
  } else if (type.type === 'record') {
    if (val === null || val === undefined || typeof val !== 'object') {
      throw new CustomError('unable to map empty record', {
        key: prefix,
      });
    }

    let data: Record<string, StateValue> = {};
    for (const [key, value] of Object.entries(val)) {
      const recordData = iterateData(
        type.valueType,
        prefix.length > 0
          ? `${prefix}."${key.replaceAll('"', '\\"')}"`
          : `"${key.replaceAll('"', '\\"')}"`,
        value,
      );
      data = { ...data, ...recordData };
    }

    return data;
  } else if (type.type === 'func' || type.type === 'promise') {
    throw new CustomError('unparsable type', {
      type: type.type,
    });
  } else {
    failNever(type, 'unexpected type');
  }
}

export function getPartialStateKeys<
  TState extends StateDefinition<any, any, any>,
>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  const keys: Record<string, StateValue> = {};

  for (const key of state.key) {
    const value = key in val ? (val as any)[key] : null;
    if (isStateValue(value)) {
      keys[key] = value;
    }
  }

  return keys;
}
export function getStateKeys<TState extends StateDefinition<any, any, any>>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  const keys: Record<string, StateValue> = {};

  for (const key of state.key) {
    const value = key in val ? (val as any)[key] : null;
    if (isStateValue(value)) {
      keys[key] = value;
    } else {
      throw new CustomError('invalid key value', { key });
    }
  }

  return keys;
}

export function deserializeData<T>(
  description: TypeDescription,
  data: Record<string, StateValue>,
): T {
  return iterateMap(description, '', { ...data });
}

export function mapToState<TState extends StateDefinition<any, any, any>>(
  state: TState,
  data: Record<string, StateValue>,
): TypeOfStateDeclaration<TState> {
  return deserializeData(state.type, data);
}

function getValuesWithPrefix(
  prefix: string,
  value: Record<string, StateValue>,
): Record<string, StateValue> {
  return Object.entries(value).reduce<Record<string, StateValue>>(
    (map, [key, value]) => {
      if (key.startsWith(prefix)) {
        map[key] = value;
      }
      return map;
    },
    {},
  );
}

function iterateMap(
  type: TypeDescription,
  prefix: string,
  value: Readonly<Record<string, StateValue>>,
): any {
  if (type.type === 'string') {
    if (typeof value[prefix] === 'string') {
      return value[prefix];
    } else {
      throw new CustomError('unable to map string', {
        key: prefix,
      });
    }
  } else if (type.type === 'number') {
    if (typeof value[prefix] === 'number') {
      return value[prefix];
    } else {
      throw new CustomError('unable to map number', {
        key: prefix,
      });
    }
  } else if (type.type === 'boolean') {
    if (typeof value[prefix] === 'boolean') {
      return value[prefix];
    } else {
      throw new CustomError('unable to map boolean', {
        key: prefix,
      });
    }
  } else if (type.type === 'date') {
    const date = getDate(value[prefix]);
    if (date) {
      return date;
    } else {
      throw new CustomError('unable to map date', {
        key: prefix,
      });
    }
  } else if (type.type === 'numberAsText') {
    if (
      typeof value[prefix] === 'string' &&
      !isNaN(parseFloat(value[prefix]))
    ) {
      return value[prefix];
    } else {
      throw new CustomError('unable to map numberAsText', {
        key: prefix,
      });
    }
  } else if (type.type === 'literal') {
    if (
      value[prefix] === type.constant &&
      (typeof value[prefix] === 'string' ||
        typeof value[prefix] === 'number' ||
        typeof value[prefix] === 'boolean')
    ) {
      return value[prefix];
    } else {
      throw new CustomError('unable to map literal', {
        key: prefix,
      });
    }
  } else if (type.type === 'array') {
    const result: any[] = [];
    let i = 0;
    let indexValue: Record<string, StateValue> = {};

    do {
      indexValue = getValuesWithPrefix(getPrefixedKey(prefix, `${i}`), value);
      if (Object.keys(indexValue).length > 0) {
        result.push(
          iterateMap(type.itemType, getPrefixedKey(prefix, `${i}`), indexValue),
        );
      } else {
        break;
      }
      i++;
    } while (Object.keys(indexValue).length > 0);
    return result;
  } else if (type.type === 'object') {
    const result: any = {};

    for (const [key, propType] of Object.entries(type.object)) {
      const propValues = getValuesWithPrefix(
        getPrefixedKey(prefix, key),
        value,
      );
      const propValue = iterateMap(
        propType,
        getPrefixedKey(prefix, key),
        propValues,
      );
      if (propValue !== undefined) {
        result[key] = propValue;
      }
    }

    return result;
  } else if (type.type === 'nullable') {
    if (Object.keys(value).length === 0) {
      return null;
    } else {
      return iterateMap(type.nullableType, prefix, value);
    }
  } else if (type.type === 'optional') {
    if (Object.keys(value).length === 0) {
      return undefined;
    } else {
      return iterateMap(type.optionalType, prefix, value);
    }
  } else if (type.type === 'union') {
    for (const unionType of type.unionTypes) {
      try {
        return iterateMap(unionType, prefix, value);
        // eslint-disable-next-line no-empty
      } catch {}
    }
    throw new CustomError('unparsable union', {
      unionName: type.name,
    });
  } else if (type.type === 'record') {
    const result: any = {};

    const recordValues = getValuesWithPrefix(getPrefixedKey(prefix, ''), value);
    for (const [key, recordValue] of Object.entries(recordValues)) {
      const keyWithoutPrefix = key.substring(prefix.length + 1);
      const stopKeyIndex = keyWithoutPrefix.match(/[^\\]"/)?.index ?? 0;
      const recordKey = keyWithoutPrefix.substring(1, stopKeyIndex + 1);
      const escapedRecordKey = recordKey.replaceAll('\\"', '"');
      if (keyWithoutPrefix.substring(stopKeyIndex).indexOf('.') === -1) {
        result[escapedRecordKey] = iterateMap(type.valueType, key, {
          [key]: recordValue,
        });
      } else {
        const subRecordValues = getValuesWithPrefix(
          getPrefixedKey(prefix, `"${recordKey}"`),
          value,
        );
        result[escapedRecordKey] = iterateMap(
          type.valueType,
          getPrefixedKey(prefix, `"${recordKey}"`),
          subRecordValues,
        );
      }
    }
    return result;
  } else if (type.type === 'func' || type.type === 'promise') {
    throw new CustomError('unparsable type', {
      type: type.type,
    });
  } else {
    failNever(type, 'unexpected type');
  }
}

function getPrefixedKey(prefix: string, key: string) {
  if (prefix.length === 0) {
    return key;
  } else {
    return `${prefix}.${key}`;
  }
}

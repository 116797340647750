import { HttpTreeNode } from './http-tree-node.js';
import { HttpRequest } from '../http-request.js';

export function stringifyPath<Params, HttpResponse>(
  node: HttpTreeNode<HttpRequest, HttpResponse, Params>,
  params: Params,
): string {
  let path = '';
  for (const segment of node.segments) {
    if (segment.type === 'path') {
      path += path.length === 0 ? segment.path : '/' + segment.path;
    } else {
      path +=
        path.length === 0
          ? (params as any)[segment.param]
          : '/' + (params as any)[segment.param];
    }
  }
  return path;
}

export interface JwtSession {
  resolveToken(): Promise<string>;
}

export interface SessionManager {
  resolveToken(): Promise<string | undefined>;
}

export function createSessionManager() {
  let session: JwtSession | null = null;
  // TODO add signal as parameter
  let controller = new AbortController();

  return {
    async resolveToken(): Promise<string | undefined> {
      if (!session) {
        return undefined;
      }
      return session.resolveToken();
    },
    addSession(fn: (signal: AbortSignal) => JwtSession) {
      session = fn(controller.signal);
    },
    clearSession() {
      controller.abort();
      controller = new AbortController();
      session = null;
    },
  };
}

import { StateDefinitions } from '@aion/core/storage/state-declaration.js';
import { EventDeclarations } from '@aion/core/event-store/event-declarations.js';
import { StreamInterface } from '@aion/core/stream/stream-interface.js';
import { StoredEvent } from '@aion/core/event-store/stored-event.js';
import {
  StreamPushBatchOptions,
  StreamPushOptions,
} from '@aion/core/stream/stream-push-options.js';
import { createRemoteObservedClient } from './create-remote-observed-client.js';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { createRemoteSnapshotClient } from './create-remote-snapshot-client.js';
import { RemoteDescription } from './remote-description.js';
import { RpcClient } from '../api/create-rpc-client.js';
import { SessionManager } from '../auth/jwt-session.js';
import { Lazy } from '@aion/core/lazy/lazy.js';
import { EventRecord } from '@aion/core/event-store/event-record.js';
import {
  deserializeData,
  iterateData,
} from '@aion/core/runtime/get-state-data-keys.js';
import { getOrFail } from '@aion/core/utils/get-or-fail.js';
import { ObservedClient } from '@aion/core/storage/observer/observed-client.js';
import { CustomError } from '@aion/core/error/custom-error.js';
import { RuntimeResult } from '@aion/core/runtime/runtime-result.js';
import { EventPushBatchItem } from '../api/event-push.js';
import { EventPushBatchResult } from '@aion/core/event-store/event-push-batch-result.js';
import { EventPushBatchEvent } from '@aion/core/event-store/event-push-batch-event.js';
import { GetOptions } from '@aion/core/storage/get-options.js';
import { RangeOptions } from '@aion/core/storage/range-options.js';

export function createRemoteStreamInterface<
  TState extends StateDefinitions,
  TEvent extends EventDeclarations,
>(
  state: TState,
  event: TEvent,
  rpc: RpcClient,
  session: SessionManager,
  lazyVersion: Lazy<string>,
  streamIdentifier: RemoteDescription,
): StreamInterface<TState, TEvent> {
  return {
    state,
    async result(id: string, opts: GetOptions): Promise<RuntimeResult | null> {
      return await rpc['event.result'].call(
        {
          realm: streamIdentifier.realm,
          tenant: streamIdentifier.tenant,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          id: id,
          args: streamIdentifier.args,
          token: await session.resolveToken(),
        },
        opts.signal,
      );
    },
    // async get(id: string, opts: GetOptions): Promise<StoredEvent | null> {
    //   const response = await rpc['event.get'].call(
    //     {
    //       realm: streamIdentifier.realm,
    //       tenant: streamIdentifier.tenant,
    //       version: await lazyVersion.resolve(),
    //       pattern: streamIdentifier.pattern,
    //       id: id,
    //       args: streamIdentifier.args,
    //       token: await session.resolveToken(),
    //     },
    //     opts.signal,
    //   );
    //   if (!response) {
    //     return null;
    //   }
    //
    //   return {
    //     ...response,
    //     data: deserializeData(
    //       getOrFail(event, response.event).type,
    //       response.data,
    //     ),
    //     toEventRecord(): EventRecord {
    //       return response;
    //     },
    //   };
    // },
    // async *iterate(
    //   date: Date | null,
    //   signal: AbortSignal,
    // ): AsyncGenerator<StoredEvent> {
    //   for await (const response of rpc['event.iterate'].call(
    //     {
    //       realm: streamIdentifier.realm,
    //       tenant: streamIdentifier.tenant,
    //       version: await lazyVersion.resolve(),
    //       pattern: streamIdentifier.pattern,
    //       date: date ?? undefined,
    //       args: streamIdentifier.args,
    //       token: await session.resolveToken(),
    //     },
    //     signal,
    //   )) {
    //     yield {
    //       ...response,
    //       data: deserializeData(
    //         getOrFail(event, response.event).type,
    //         response.data,
    //       ),
    //       toEventRecord(): EventRecord {
    //         return response;
    //       },
    //     };
    //   }
    // },
    async *range(
      date: Date | null,
      options: RangeOptions,
    ): AsyncGenerator<StoredEvent> {
      for await (const response of rpc['event.range'].call(
        {
          realm: streamIdentifier.realm,
          tenant: streamIdentifier.tenant,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          date: date ?? undefined,
          args: streamIdentifier.args,
          token: await session.resolveToken(),
        },
        options.signal,
      )) {
        yield {
          ...response,
          data: deserializeData(
            getOrFail(event, response.event).type,
            response.data,
          ),
          toEventRecord(): EventRecord {
            return response;
          },
        };
      }
    },
    async push<K extends string & keyof TEvent>(
      evt: K,
      data: TEvent[K]['type']['_output'],
      options: StreamPushOptions,
    ): Promise<StoredEvent> {
      const type = getOrFail(event, evt);
      const storedData = iterateData(type.type, '', data);
      const response = await rpc['event.push'].call(
        {
          realm: streamIdentifier.realm,
          tenant: streamIdentifier.tenant,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          event: evt,
          data: storedData,
          annotations: options?.annotations,
          createdAt: options?.createdAt,
          id: options?.id,
          args: streamIdentifier.args,
          await: options?.await,
          token: await session.resolveToken(),
        },
        options.signal,
      );
      if (response === null) {
        throw new CustomError('unknown event', { event: evt });
      }
      return {
        ...response,
        data: deserializeData(type.type, response.data),
        toEventRecord(): EventRecord {
          return response;
        },
      };
    },
    async pushBatch(
      events: EventPushBatchEvent<TEvent, string & keyof TEvent>[],
      options: StreamPushBatchOptions,
    ): Promise<EventPushBatchResult> {
      const response = await rpc['event.pushBatch'].call(
        {
          realm: streamIdentifier.realm,
          tenant: streamIdentifier.tenant,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          args: streamIdentifier.args,
          await: options?.await,
          token: await session.resolveToken(),
          events: events.map<EventPushBatchItem>((e) => {
            const type = getOrFail(event, e.evt);
            const storedData = iterateData(type.type, '', e.data);
            return {
              data: storedData,
              event: e.evt,
              annotations: e.annotations,
              createdAt: e.createdAt ?? new Date(),
              id: e.id,
            };
          }),
        },
        options.signal,
      );

      return {
        items: response.items.map((item) => {
          if (item.type === 'error') {
            return item;
          } else {
            const type = getOrFail(event, item.event.event).type;
            return {
              type: 'event',
              event: {
                ...item.event,
                data: deserializeData(type, item.event.data),
                toEventRecord(): EventRecord {
                  return item.event;
                },
              },
            };
          }
        }),
      };
    },
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return createRemoteObservedClient(
        state[name]!,
        rpc,
        session,
        lazyVersion,
        'stream',
        streamIdentifier,
        name,
      );
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createRemoteSnapshotClient(
        state[name]!,
        rpc,
        session,
        lazyVersion,
        'stream',
        streamIdentifier,
        name,
      );
    },
  };
}

export class CustomError extends Error {
  readonly data: Record<string, string>;

  constructor(
    message: string,
    data: { [key: string]: string | undefined | null },
  ) {
    super(
      `${message}${Object.keys(data).length > 0 ? ` (${JSON.stringify(data)})` : ''}`,
    );
    this.data = Object.entries(data).reduce<Record<string, string>>(
      (map, [key, value]) => {
        if (value) {
          map[key] = value;
        }
        return map;
      },
      {},
    );
  }
}

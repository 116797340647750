import { BrowseResult } from '@aion/core/storage/browse-result.js';
import { StateValue } from '@aion/core/storage/state-value.js';
import { mapToState } from '@aion/core/runtime/get-state-data-keys.js';
import { StateDefinition } from '@aion/core/storage/state-declaration.js';
import { RangeResult } from '@aion/core/storage/range-result.js';
import { RangeOptions } from '@aion/core/storage/range-options.js';

export async function* iterateResponse<
  TState extends StateDefinition<any, any, any>,
>(
  state: TState,
  bookmark: Record<string, StateValue> | null,
  opts: RangeOptions,
  req: (
    bookmark: Record<string, StateValue> | null,
    opts: RangeOptions,
  ) => Promise<BrowseResult<Record<string, StateValue>>>,
): AsyncGenerator<RangeResult<TState>> {
  const options: RangeOptions = {
    dir: opts?.dir ?? 'next',
    open: opts?.open ?? false,
    signal: opts.signal,
  };

  let currentBookmark = bookmark;
  do {
    const response = await req(currentBookmark, options);

    for (const item of response.items) {
      yield {
        data: mapToState(state, item.data),
        keys: item.keys,
      };
    }
    currentBookmark = response.bookmark;
    if (response.items.length < 10) {
      break;
    }
  } while (true);
}

import { JwtSessionStorage } from '@aion/client/auth/jwt-session-storage.js';
import { validate } from '@aion/core/typing/validate.js';
import { TypeDescription } from '@aion/core/typing/type-description.js';

export function createLocalSession<T extends TypeDescription>(
  key: string = 'auth',
  type: T,
): JwtSessionStorage<T['_output']> {
  return {
    clear() {
      localStorage.removeItem(key);
      return Promise.resolve();
    },
    load() {
      const value = localStorage.getItem(key);
      if (value) {
        try {
          const data = JSON.parse(value);
          const [, parsed] = validate(data, type);
          if (parsed) {
            return Promise.resolve(parsed);
          }
        } catch {
          return Promise.resolve(null);
        }
      }
      return Promise.resolve(null);
    },
    save(state) {
      localStorage.setItem(key, JSON.stringify(state));
      return Promise.resolve();
    },
  };
}

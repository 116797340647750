import { HttpTreeNode } from './http-tree-node.js';
import { paramNode } from './param-node.js';
import { pathNode } from './path-node.js';
import { ObjectTypeDescription } from '../../typing/object-type-description.js';
import { HttpRequest } from '../http-request.js';
import { HttpSource } from '../server/http-rpc.js';
import { CustomError } from '../../error/custom-error.js';
import { getOrFail } from '../../utils/get-or-fail.js';

export function parsePath<HttpResponse>(
  node: HttpTreeNode<HttpRequest, HttpResponse, any>,
  path: string,
  input: ObjectTypeDescription<any>,
  mapping: Record<string, HttpSource>,
): HttpTreeNode<HttpRequest, HttpResponse, any> {
  const parts = path.split('/').filter((s) => !!s);

  let current = node;
  for (const part of parts) {
    if (part.startsWith('{') && part.endsWith('}')) {
      const name = part.substring(1, part.length - 1);
      const pathMapping = mapping[name];
      if (!pathMapping || pathMapping.type !== 'path') {
        throw new CustomError(`path part is not mapped to path`, {
          name,
        });
      }
      current = paramNode(
        current,
        part.substring(1, part.length - 1),
        getOrFail(input.object, name),
      );
    } else {
      current = pathNode(current, part);
    }
  }
  return current;
}

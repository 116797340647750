import { DelayedEmitter, Emitter, LazyEmitter } from './emitter.js';
import { Listener } from './listener.js';
import { createLogger } from '../logger/logger.js';
import { getErrorMessage } from '../runtime/get-error-message.js';

export function createEmitter<T>(initialValue: T): Emitter<T> {
  const listeners: Listener<T>[] = [];

  let value = initialValue;
  return {
    value: () => {
      return value;
    },
    patch(fn: (value: Readonly<T>) => T) {
      const newValue = fn(value);
      this.emit(newValue);
    },
    on: (cb: Listener<T>, signal: AbortSignal) => {
      signal.addEventListener(
        'abort',
        () => {
          const index = listeners.indexOf(cb);
          if (index >= 0) {
            listeners.splice(index, 1);
          }
        },
        { once: true },
      );
      listeners.push(cb);
      cb(value);
    },
    emit(val: T) {
      value = val;
      for (const listener of listeners) {
        listener(val);
      }
    },
  };
}

const logger = createLogger('emitter');
export function createDelayedEmitter<T>(
  initialValue: Promise<T>,
): DelayedEmitter<T> {
  const listeners: Listener<T>[] = [];

  let value = initialValue;
  return {
    value: async () => {
      return await value;
    },
    on: (cb: Listener<T>, signal: AbortSignal) => {
      signal.addEventListener(
        'abort',
        () => {
          const index = listeners.indexOf(cb);
          if (index >= 0) {
            listeners.splice(index, 1);
          }
        },
        { once: true },
      );
      listeners.push(cb);
      value
        .then((v) => cb(v))
        .catch((err) => {
          logger.error(getErrorMessage(err));
        });
    },
    emit(val: T) {
      value = Promise.resolve(val);
      for (const listener of listeners) {
        listener(val);
      }
    },
  };
}

export function createLazyEmitter<T>(): LazyEmitter<T> {
  const listeners: Listener<T>[] = [];

  return {
    on: (cb: Listener<T>, signal: AbortSignal) => {
      signal.addEventListener(
        'abort',
        () => {
          const index = listeners.indexOf(cb);
          if (index >= 0) {
            listeners.splice(index, 1);
          }
        },
        { once: true },
      );
      listeners.push(cb);
    },
    emit(val: T) {
      for (const listener of listeners) {
        listener(val);
      }
    },
  };
}

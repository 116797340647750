import { cmd } from '@aion/core/rpc/client/cmd.js';
import { defineRpc } from '@aion/core/rpc/server/server.js';
import { TokenResponse } from '@aion/core/management/authentication/token-response.js';
import { StateBrowse } from './state-browse.js';
import { BrowseItem } from './browse-item.js';
import { BrowseResult } from './browse-result.js';
import { StateGet } from './state-get.js';
import { StateIndexBrowse } from './state-index-browse.js';
import { IndexBrowseResult } from './index-browse-result.js';
import { EventPush, EventPushBatch } from './event-push.js';
import { GetEvent, IterateEvent } from './get-event.js';
import { AuthLoginRequest } from './auth-login-request.js';
import { AuthRegisterRequest } from './auth-register-request.js';
import { AuthorizeRequest } from './authorize-request.js';
import { AuthRefresh } from './auth-refresh.js';
import {
  EventRecord,
  EventRecordBatch,
} from '@aion/core/event-store/event-record.js';
import { nullable } from '@aion/core/typing/nullable.js';
import { AuthTokenRequest } from './auth-token-request.js';
import { AuthDeviceCodeRequest } from './auth-device-code-request.js';
import { AuthDeviceResponse } from '@aion/core/authentication/oauth/auth-device-response.js';
import { RuntimeResult } from '@aion/core/runtime/runtime-result.js';

export const loginCmd = cmd(AuthLoginRequest).returns(TokenResponse);
export const registerCmd = cmd(AuthRegisterRequest).returns(TokenResponse);
export const tokenCmd = cmd(AuthTokenRequest).returns(TokenResponse);
export const deviceCodeCmd = cmd(AuthDeviceCodeRequest).continuous(
  AuthDeviceResponse,
);
export const authorizeCmd = cmd(AuthorizeRequest).returns(TokenResponse);
export const refreshCmd = cmd(AuthRefresh).returns(TokenResponse);
export const getEvent = cmd(GetEvent).returns(EventRecord);
export const getEventResult = cmd(GetEvent).returns(RuntimeResult);
export const iterateEvent = cmd(IterateEvent).continuous(EventRecord);
export const rangeEvent = cmd(IterateEvent).continuous(EventRecord);
export const pushEvent = cmd(EventPush).returns(EventRecord);
export const pushBatchEvent = cmd(EventPushBatch).returns(EventRecordBatch);
export const stateBrowse = cmd(StateBrowse).returns(BrowseResult);
export const stateBrowseObserve = cmd(StateBrowse).continuous(BrowseResult);
export const stateIterate = cmd(StateBrowse).continuous(BrowseItem);
export const stateGet = cmd(StateGet).returns(BrowseItem);
export const stateGetObserve = cmd(StateGet).continuous(nullable(BrowseItem));
export const stateIndexBrowse =
  cmd(StateIndexBrowse).returns(IndexBrowseResult);
export const stateIndexBrowseObserve =
  cmd(StateIndexBrowse).continuous(IndexBrowseResult);
export const stateIndexIterate = cmd(StateIndexBrowse).continuous(BrowseItem);

export const rpcApiDefinition = defineRpc({
  'auth.login': loginCmd,
  'auth.register': registerCmd,
  'auth.token': tokenCmd,
  'auth.deviceCode': deviceCodeCmd,
  'auth.authorize': authorizeCmd,
  'auth.refresh': refreshCmd,

  //'event.get': getEvent,
  //'event.iterate': iterateEvent,
  'event.range': rangeEvent,
  'event.result': getEventResult,
  'event.push': pushEvent,
  'event.pushBatch': pushBatchEvent,

  'state.browse': stateBrowse,
  'state.browse.observe': stateBrowseObserve,
  'state.index.browse': stateIndexBrowse,
  'state.index.browse.observe': stateIndexBrowseObserve,
  'state.get': stateGet,
  'state.get.observe': stateGetObserve,
  'state.iterate': stateIterate,
  'state.index.iterate': stateIndexIterate,
});

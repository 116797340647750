import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { StreamArgs } from './stream-args.js';
import { optional } from '@aion/core/typing/optional.js';
import { date } from '@aion/core/typing/date.js';

export const GetEvent = object('GetEvent', {
  realm: string(),
  tenant: string(),
  version: string(),
  pattern: string(),
  id: string(),
  args: StreamArgs,

  token: optional(string()),
});

export const IterateEvent = object('IterateEvent', {
  realm: string(),
  tenant: string(),
  version: string(),
  pattern: string(),
  args: StreamArgs,

  date: optional(date()),

  token: optional(string()),
});

import { TypeOf } from '../../typing/type-of.js';
import { string } from '../../typing/string.js';
import { object } from '../../typing/object.js';
import { number } from '../../typing/number.js';

export const TokenResponse = object('TokenResponse', {
  id_token: string(),
  refresh_token: string(),
  scope: string(),
  expires_in: number(),
  username: string(),
});
export type TokenResponse = TypeOf<typeof TokenResponse>;

import { ValidateError } from './validate-error.js';
import { TypeDescription } from './type-description.js';
import { SafeParseReturnType } from './safe-parse-return-type.js';
import { isArray } from './array.js';
import { getDate } from './date.js';
import { failNever } from '../utils/fail-never.js';

export function validate<T extends TypeDescription>(
  val: unknown,
  type: T,
): [ValidateError, null] | [null, T['_output']] {
  const result = safeParse(val, type, []);
  if (result.success) {
    return [null, result.data];
  } else {
    return [result.error, null];
  }
}

function getObjectPropertyValue(objectValue: object, key: string) {
  if (!!objectValue && key in objectValue) {
    return (objectValue as any)[key];
  } else {
    return undefined;
  }
}

export function safeParse(
  objectValue: unknown,
  type: TypeDescription,
  path: string[],
): SafeParseReturnType<any> {
  if (type.type === 'string') {
    if (typeof objectValue === 'string') {
      return { success: true, data: objectValue };
    } else {
      return {
        success: false,
        error: new ValidateError('not a valid string', path, objectValue),
      };
    }
  } else if (type.type === 'union') {
    for (const kind of type.unionTypes) {
      const res = safeParse(objectValue, kind, path);
      if (res.success) {
        return { success: true, data: res.data };
      }
    }
    return {
      success: false,
      error: new ValidateError('not union type', path, objectValue),
    };
  } else if (type.type === 'record') {
    if (objectValue === null || objectValue === undefined) {
      return {
        success: false,
        error: new ValidateError('record not defined', path, objectValue),
      };
    }

    if (typeof objectValue !== 'object') {
      return {
        success: false,
        error: new ValidateError('not an object', path, objectValue),
      };
    }

    const result: { [key: string]: any } = {};
    const keys = Object.entries(objectValue);
    for (const [key, keyValue] of keys) {
      const keyValidation = safeParse(key, type.keyType, [...path, key]);
      if (!keyValidation.success) {
        return keyValidation;
      }

      const prop = safeParse(keyValue, type.valueType, [...path, key]);
      if (!prop.success) {
        return prop;
      }
      result[key] = prop.data;
    }

    return { success: true, data: result };
  } else if (type.type === 'promise') {
    if (isPromise(objectValue)) {
      return {
        success: true,
        data: objectValue.then((result) => {
          const [err, data] = validate(result, type.returnType);
          if (err) {
            throw err;
          } else {
            return data;
          }
        }),
      };
    }

    return {
      success: false,
      error: new ValidateError(
        'not a valid function at ' + path.join('->'),
        path,
        objectValue,
      ),
    };
  } else if (type.type === 'number') {
    if (typeof objectValue === 'number') {
      return { success: true, data: objectValue };
    } else {
      return {
        success: false,
        error: new ValidateError('not a valid number', path, objectValue),
      };
    }
  } else if (type.type === 'literal') {
    if (objectValue === type.constant) {
      return { success: true, data: objectValue };
    } else {
      return {
        success: false,
        error: new ValidateError(`not ${type.constant}`, path, objectValue),
      };
    }
  } else if (type.type === 'date') {
    const date = getDate(objectValue);
    if (date) {
      return { success: true, data: date };
    } else {
      return {
        success: false,
        error: new ValidateError(
          'not a valid date at ' + path.join('->'),
          path,
          objectValue,
        ),
      };
    }
  } else if (type.type === 'boolean') {
    if (typeof objectValue === 'boolean') {
      return { success: true, data: objectValue };
    } else {
      return {
        success: false,
        error: new ValidateError('not a valid boolean', path, objectValue),
      };
    }
  } else if (type.type === 'func') {
    if (typeof objectValue === 'function') {
      return {
        success: true,
        data: (...args: unknown[]) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const result = objectValue(...args);
          const [err, data] = validate(result, type.returnType);
          if (err) {
            throw err;
          }
          return data;
        },
      };
    }

    return {
      success: false,
      error: new ValidateError(
        'not a valid function at ' + path.join('->'),
        path,
        objectValue,
      ),
    };
  } else if (type.type === 'nullable') {
    if (objectValue === null || objectValue === undefined) {
      return { success: true, data: null };
    } else {
      return safeParse(objectValue, type.nullableType, path);
    }
  } else if (type.type === 'optional') {
    if (objectValue === null || objectValue === undefined) {
      return { success: true, data: undefined };
    } else {
      return safeParse(objectValue, type.optionalType, path);
    }
  } else if (type.type === 'numberAsText') {
    if (typeof objectValue === 'string') {
      const num = parseInt(objectValue);
      if (!isNaN(num)) {
        return { success: true, data: num };
      } else {
        return {
          success: false,
          error: new ValidateError('not a valid number', path, objectValue),
        };
      }
    } else {
      return {
        success: false,
        error: new ValidateError('not a valid string', path, objectValue),
      };
    }
  } else if (type.type === 'array') {
    if (isArray(objectValue)) {
      const data: any[] = [];
      let index = 0;
      for (const item of objectValue) {
        const result = safeParse(item, type.itemType, [
          ...path,
          index.toString(),
        ]);
        if (result.success) {
          data.push(result.data);
        } else {
          return result;
        }
        index++;
      }
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        error: new ValidateError('not an array', path, objectValue),
      };
    }
  } else if (type.type === 'object') {
    if (objectValue === null || objectValue === undefined) {
      return {
        success: false,
        error: new ValidateError(
          'object not defined',
          [...path, type.name],
          objectValue,
        ),
      };
    }

    if (typeof objectValue !== 'object') {
      return {
        success: false,
        error: new ValidateError(
          'not an object',
          [...path, type.name],
          objectValue,
        ),
      };
    }

    const result: { [key: string]: any } = {};
    const props = Object.entries(type.object);
    for (const [propName, propType] of props) {
      const propValue = getObjectPropertyValue(objectValue, propName);

      const parsedValue = safeParse(propValue, propType, [
        ...path,
        type.name,
        propName,
      ]);
      if (!parsedValue.success) {
        return parsedValue;
      }
      result[propName] = parsedValue.data;
    }

    return { success: true, data: result as any };
  } else {
    failNever(type, 'unknown type');
  }
}

function isPromise(objectValue: unknown): objectValue is Promise<unknown> {
  return (
    objectValue instanceof Promise ||
    ('constructor' in (objectValue as any) &&
      (objectValue as any).constructor.name === 'Promise' &&
      typeof (objectValue as any)['then'] === 'function')
  );
}

import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';

export const EventAuth = object('EventAuth', {
  sub: string(),
  aud: string(),
  iss: string(),
});
export type EventAuth = TypeOf<typeof EventAuth>;

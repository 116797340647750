import { JwtSessionData } from './jwt-session-data.js';
import { JwtSession } from './jwt-session.js';
import { nowInSeconds } from '@aion/core/utils/now-in-seconds.js';
import { CustomError } from '@aion/core/error/custom-error.js';
import { RpcClient } from '../api/create-rpc-client.js';
import { reusableResolvable } from '@aion/core/utils/reusable-resolvable.js';
import { JwtSessionStorage } from './jwt-session-storage.js';

export function createJwtSessionHandler(
  rpcClient: RpcClient,
  opts: {
    data: JwtSessionData;
    signal: AbortSignal;
    sessionStorage: JwtSessionStorage<JwtSessionData>;
  },
): JwtSession {
  let token = opts.data;

  const reusableRefresh = reusableResolvable<string>();

  async function handleSessionResponse(
    data:
      | undefined
      | {
          id_token: string;
          refresh_token: string;
          expires_in: number;
        },
  ): Promise<string> {
    if (!data) {
      throw new CustomError('invalid session', {});
    }

    token = {
      accessToken: data.id_token,
      refreshToken: data.refresh_token,
      exp: nowInSeconds() + data.expires_in,
      provider: token.provider,
      realm: token.realm,
      tenant: token.tenant,
      username: token.username,
    };
    await opts.sessionStorage.save(token);

    return token.accessToken;
  }

  return {
    async resolveToken(): Promise<string> {
      if (opts.signal.aborted) {
        throw new CustomError('aborted', {});
      }

      const now = nowInSeconds();

      if (token && token.exp > now + 20) {
        return token.accessToken;
      }

      return reusableRefresh.resolve(async () => {
        const response = await rpcClient['auth.refresh'].call(
          {
            tenant: token.tenant,
            realm: token.realm,
            provider: token.provider,
            refreshToken: token.refreshToken,
            username: token.username,
          },
          opts.signal,
        );

        return handleSessionResponse(response);
      });
    },
  };
}

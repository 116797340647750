import { HttpTreeNode } from './http-tree-node.js';
import { HttpRequest } from '../http-request.js';

export function newRootNode<HttpResponse>(): HttpTreeNode<
  HttpRequest,
  HttpResponse,
  {}
> {
  return {
    _req: undefined as any,
    _res: undefined as any,
    _params: undefined as any,
    routing: {
      paramNode: null,
      methods: {},
      pathNodes: {},
      segments: [],
    },
    queryArgs: {},
    headers: {},
    segments: [],
    middlewares: [],
    extras: {},
  };
}

import { DestroyRef, inject, Injectable } from '@angular/core';
import { createOAuthStorage } from '@aion/client/oauth/oauth-storage.js';
import { createBrowserEnv } from '@aion/browser/create-browser-env';
import { Router } from '@angular/router';
import { createRemoteManagement } from '@aion/client/management/create-remote-management.js';
import { createLocalSession } from '@aion/browser/create-local-session.js';
import { signalFromDestroyRef } from './signal-from-destroy-ref.js';
import { ApiConfig } from './provide-api.js';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  destroyRef = inject(DestroyRef);
  router = inject(Router);
  apiConfig = inject(ApiConfig);

  tenant = this.apiConfig.tenant;
  authProviders = this.apiConfig.realm.providers;
  session = createOAuthStorage(this.apiConfig.realm, {
    sessionStorageFactory: createLocalSession,
    transferStorageFactory: createLocalSession,
  });
  signal = signalFromDestroyRef(this.destroyRef);

  remote = createRemoteManagement({
    url: this.apiConfig.host,
    tenant: this.apiConfig.tenant,
    session: this.session,
    env: createBrowserEnv(),
    signal: this.signal,
  });

  constructor() {
    this.remote.session.on(async (evt) => {
      if (evt.type === 'error') {
        console.error(evt.error);
        await this.remote.session.logout();
        //await this.offline.session.logout();
        await this.router.navigate(['/auth/login']);
      } else if (evt.type === 'unauthenticated') {
        console.log('navigate to login');
        await this.router.navigate(['/auth/login']);
      } else {
        console.log(evt);
      }
    }, this.signal);
  }
}

import { RpcDefinitions } from '../server/server.js';
import { HttpRpcDefinition } from '../server/http-rpc.js';
import { RpcFetchClientErrorHandler, RpcFetchClientOptions } from './client.js';
import { RpcClientApp } from './app.js';
import { fetchHttpClient } from './browser-client.js';
import { bodyAsJson } from './body-as-json.js';
import { validate } from '../../typing/validate.js';
import { object } from '../../typing/object.js';
import { string } from '../../typing/string.js';
import { record } from '../../typing/record.js';
import { CustomError } from '../../error/custom-error.js';
import { RpcClientFunction } from './function.js';
import { HttpMethod } from './http-method.js';
import { failNever } from '../../utils/fail-never.js';
import { newRootNode } from '../http-tree/new-root-node.js';
import { parsePath } from '../http-tree/parse-path.js';
import { stringifyPath } from '../http-tree/stringify-path.js';
import { BadRequestError } from '../../error/bad-request-error.js';

export function rpcFetchClient<T extends RpcDefinitions>(
  definition: T,
  httpDefinition: HttpRpcDefinition<T>,
  options: RpcFetchClientOptions,
): RpcClientApp<T> {
  const client = fetchHttpClient({ baseUrl: options.url, env: options.env });
  const errorHandler: RpcFetchClientErrorHandler =
    options?.errorHandler ??
    (async (res) => {
      const contentType = res.header('Content-Type');
      if (contentType === 'application/json') {
        if (res.statusCode === 400) {
          const body = await bodyAsJson(res);
          const [, data] = validate(
            body,
            object('BadRequest', {
              message: string(),
              data: record(string(), string()),
            }),
          );
          if (data) {
            return new BadRequestError(data.message, data.data);
          }
          return new BadRequestError('bad request error', {});
        } else if (res.statusCode === 500) {
          return new CustomError('internal server error', {});
        } else if (res.statusCode === 504) {
          return new CustomError('gateway timeout error', {});
        } else if (res.statusCode === 503) {
          return new CustomError('server unavailable error', {});
        }
      }

      return null;
    });

  const root = newRootNode();

  function mapToRequestMethod(method: HttpMethod) {
    if (method === 'POST') {
      return 'post' as const;
    } else if (method === 'GET') {
      return 'get' as const;
    } else if (method === 'PUT') {
      return 'put' as const;
    } else if (method === 'HEAD') {
      return 'head' as const;
    } else if (method === 'OPTIONS') {
      return 'options' as const;
    } else if (method === 'DELETE') {
      return 'head' as const;
    } else if (method === 'PATCH') {
      return 'patch';
    } else {
      failNever(method, 'unknown http method');
    }
  }

  return Object.entries(definition).reduce<RpcClientApp<any>>(
    (app, [key, fn]) => {
      const http = httpDefinition[key];
      if (!http) {
        throw new CustomError('http definition not found', { key });
      }

      if (fn.type === 'continuous') {
        const rpcClient: RpcClientFunction<any, any> = {
          async *call(arg: any, signal: AbortSignal): AsyncGenerator<any> {
            const [err, data] = validate(arg, fn.input);
            if (err) {
              throw err;
            }

            const pathNode = parsePath(root, http.path, fn.input, http.mapping);
            const parsedPath = stringifyPath(pathNode, data);

            let request = client;

            const body: Record<string, any> = {};
            for (const [mappingName, mapping] of Object.entries(http.mapping)) {
              const value = data[mappingName];
              if (value === undefined) {
                continue;
              }
              if (mapping.type == 'body') {
                body[mappingName] = value;
              } else if (mapping.type === 'header') {
                request = request.header(
                  mappingName,
                  btoa(JSON.stringify(value)),
                );
              } else if (mapping.type === 'query') {
                request = request.query(
                  mappingName,
                  btoa(JSON.stringify(value)),
                );
              }
            }

            if (Object.keys(body).length > 0) {
              request = request.body(JSON.stringify(body));
            }

            const result = await request[mapToRequestMethod(http.method)]({
              path: parsedPath,
              signal,
            });

            for await (const data of result.bodyAsEventStream()) {
              for (const line of data.split('\n')) {
                if (line.startsWith('data: ')) {
                  yield JSON.parse(line.substring('data: '.length));
                }
              }
            }

            const error = await errorHandler(result);
            if (error) {
              throw error;
            }
          },
        };
        app[key] = rpcClient;
      } else {
        const rpcClient: RpcClientFunction<any, any> = {
          async call(arg: any, signal: AbortSignal): Promise<any> {
            const [err, data] = validate(arg, fn.input);
            if (err) {
              throw err;
            }

            const pathNode = parsePath(root, http.path, fn.input, http.mapping);
            const parsedPath = stringifyPath(pathNode, data);

            let request = client;

            const body: Record<string, any> = {};
            for (const [mappingName, mapping] of Object.entries(http.mapping)) {
              const value = data[mappingName];
              if (value === undefined) {
                continue;
              }
              if (mapping.type == 'body') {
                body[mappingName] = value;
              } else if (mapping.type === 'header') {
                request = request.header(
                  mappingName,
                  btoa(JSON.stringify(value)),
                );
              } else if (mapping.type === 'query') {
                request = request.query(
                  mappingName,
                  btoa(JSON.stringify(value)),
                );
              }
            }

            if (Object.keys(body).length > 0) {
              request = request.body(JSON.stringify(body));
            }

            const result = await request[mapToRequestMethod(http.method)]({
              path: parsedPath,
              signal,
            });

            if (fn.type === 'return') {
              if (result.statusCode === 404) {
                return null;
              } else if (result.statusCode === 200) {
                return await bodyAsJson(result);
              }
            } else if (fn.type === 'cmd') {
              if (result.statusCode === 204) {
                return;
              }
            }

            const error = await errorHandler(result);
            if (error) {
              throw error;
            } else {
              throw new CustomError('unhandled request error', {
                statusCode: result.statusCode.toString(),
              });
            }
          },
        };
        app[key] = rpcClient;
      }
      return app;
    },
    {},
  );
}

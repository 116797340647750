import { number } from '../../typing/number.js';
import { object } from '../../typing/object.js';
import { string } from '../../typing/string.js';
import { TypeOf } from '../../typing/type-of.js';
import { optional } from '../../typing/optional.js';

export const JwtPayload = object('JwtPayload', {
  sub: string(),
  aud: string(),
  iss: string(),
  iat: optional(number()),
  nbf: optional(number()),
  exp: optional(number()),
});
export type JwtPayload = TypeOf<typeof JwtPayload>;

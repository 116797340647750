import { AbortableLazy, Lazy } from './lazy.js';

export function createLazy<T>(fn: () => Promise<T> | T): Lazy<T> {
  let value: Promise<T> | T | null = null;
  return {
    async resolve() {
      if (!value) {
        value = fn();
      }
      return value;
    },
    async close(fn: (val: T) => Promise<void> | void): Promise<void> {
      if (value) {
        await fn(await value);
        value = null;
      }
    },
  };
}

export function createAbortableLazy<T>(
  fn: (signal: AbortSignal) => Promise<T> | T,
): AbortableLazy<T> {
  let value: Promise<T> | T | null = null;
  return {
    async resolve(signal: AbortSignal) {
      if (!value) {
        value = fn(signal);
      }
      return value;
    },
    async close(fn: (val: T) => Promise<void> | void): Promise<void> {
      if (value) {
        await fn(await value);
        value = null;
      }
    },
  };
}

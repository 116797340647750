import { AuthDeviceCodeResponse } from './auth-device-code-response.js';
import { AuthDeviceTokenResponse } from './auth-device-token-response.js';
import { AuthDeviceExpiredResponse } from './auth-device-expired-response.js';
import { union } from '../../typing/union.js';
import { TypeOf } from '../../typing/type-of.js';

export const AuthDeviceResponse = union('AuthDeviceResponse', [
  AuthDeviceCodeResponse,
  AuthDeviceTokenResponse,
  AuthDeviceExpiredResponse,
]);
export type AuthDeviceResponse = TypeOf<typeof AuthDeviceResponse>;

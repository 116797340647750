export function getErrorMessage(e: unknown): string {
  if (typeof e === 'string') {
    return e;
  } else if (e instanceof Error) {
    return e.message;
  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${e}`;
  }
}

export function getError(e: unknown): Error {
  if (typeof e === 'string') {
    return new Error(e);
  } else if (e instanceof Error) {
    return e;
  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return new Error(`${e}`);
  }
}

export function getErrorStackTrace(e: unknown): string | undefined {
  if (typeof e === 'string') {
    return undefined;
  } else if (e instanceof Error) {
    return e.stack ?? undefined;
  } else {
    return undefined;
  }
}

import { failNever } from '../utils/fail-never.js';
import { LogLevel } from './log-level.js';

export interface Logger {
  info(message: string, ...values: any[]): void;
  debug(message: string, ...values: any[]): void;
  error(message: string, ...values: any[]): void;
  warn(message: string, ...values: any[]): void;
  log(level: LogLevel, message: string, ...values: any[]): void;
  raw(message: string): void;
}

let allowedLevels = ['INFO', 'WARN', 'ERROR', 'DEBUG'];
let lastLogTime = new Date().getTime();

export function setLogLevel(level: LogLevel) {
  if (level === 'DEBUG') {
    allowedLevels = ['INFO', 'WARN', 'ERROR', 'DEBUG'];
  } else if (level === 'INFO') {
    allowedLevels = ['INFO', 'WARN', 'ERROR'];
  } else if (level === 'WARN') {
    allowedLevels = ['WARN', 'ERROR'];
  } else if (level === 'ERROR') {
    allowedLevels = ['ERROR'];
  } else {
    failNever(level, 'unknown log level');
  }
}

export function createLogger(name: string): Logger {
  function log(
    level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR',
    message: string,
    ...values: any[]
  ): void {
    if (!message) {
      return;
    }

    const now = new Date();

    const prefix =
      `[${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}] ${level.padEnd(5, ' ')} : ` +
      ` ${(now.getTime() - lastLogTime).toString().padStart(4, ' ')}ms - ` +
      name.substring(0, 20).padEnd(20, ' ') +
      ' - ';

    if (allowedLevels.indexOf(level) !== -1) {
      let i = 0;
      for (const messagePart of `${message}`.split('\n')) {
        const value = prefix + messagePart;
        const args = i === 0 ? values : [];
        if (level === 'DEBUG') {
          console.debug(value, ...args);
        } else if (level === 'ERROR') {
          console.error(value, ...args);
        } else if (level === 'WARN') {
          console.warn(value, ...args);
        } else if (level === 'INFO') {
          console.info(value, ...args);
        } else {
          failNever(level, 'unknown log level');
        }
        i++;
      }
    }

    lastLogTime = now.getTime();
  }

  return {
    log,
    raw(message: string) {
      console.log(message);
    },
    info(message: string, ...values) {
      log('INFO', message, ...values);
    },
    debug(message: string, ...values) {
      log('DEBUG', message, ...values);
    },
    error(message: string, ...values) {
      log('ERROR', message, ...values);
    },
    warn(message: string, ...values) {
      log('WARN', message, ...values);
    },
  };
}

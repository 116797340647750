import { TypeDescription } from '../../typing/type-description.js';
import { CustomError } from '../../error/custom-error.js';
import { assert } from '../../typing/assert.js';
import { getErrorMessage } from '../../runtime/get-error-message.js';

export function extractPart(token: string, partIndex: 0 | 1 | 2): string {
  const tokenPart = token.split('.')[partIndex];
  if (!tokenPart) {
    throw new Error('invalid format: token does not have enough parts');
  }

  return tokenPart;
}

export function extractObject(token: string, partIndex: 0 | 1): unknown {
  const tokenPart = extractPart(token, partIndex);

  let content = '';
  try {
    content = atob(tokenPart);
  } catch (e) {
    throw new CustomError('invalid format', {
      parseError: getErrorMessage(e),
    });
  }

  let data: unknown;
  try {
    data = JSON.parse(content);
  } catch (e) {
    throw new CustomError('invalid json format', {
      jsonError: getErrorMessage(e),
    });
  }

  return data;
}

export function parseJwt<Type extends TypeDescription>(
  token: string,
  partIndex: 0 | 1,
  type: Type,
): Type['_output'] {
  const data = extractObject(token, partIndex);

  assert(data, type);

  return data;
}
